<!--
 * @Description: 会员列表
 * @Author: 琢磨先生
 * @Date: 2022-06-17 15:19:22
 * @LastEditors: cts 308360781@qq.com
 * @LastEditTime: 2025-01-07 17:04:01
-->
<template>
  <el-card class="box">
    <el-form ref="query" class="query" :model="query" :inline="true">
      <el-form-item label="关键字">
        <el-input v-model="query.q" placeholder="请输入名称、手机号" clearable style="width: 300px;"></el-input>
      </el-form-item>
      <el-form-item label="等级">
        <el-select v-model="query.level" placeholder clearable style="width: 160px;">
          <el-option
            :label="`Lv.${item.level+1} ${item.name}`"
            :value="item.level"
            :key="item.level"
            v-for="item in mg_grades"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="query.status" clearable placeholder style="width: 120px">
          <el-option label="正常" :value="0"></el-option>
          <el-option label="冻结" :value="1"></el-option>
          <el-option label="注销" :value="2"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" round @click="onSearch" icon="search">查询</el-button>
      </el-form-item>
    </el-form>
  </el-card>
  <el-card class="box data" v-loading="loading">
    <el-table :data="tableData.data" border>
      <!-- <el-table-column label="编号" prop="id" width="100"></el-table-column> -->
      <el-table-column label="头像" width="100">
        <template #default="scope">
          <el-avatar :size="50" :src="scope.row.avatar_url" />
        </template>
      </el-table-column>
      <el-table-column label="名称" prop="nick_name" min-width="250"></el-table-column>
      <el-table-column label="手机" prop="mobile_phone" width="120"></el-table-column>
      <el-table-column label="等级" width="140">
        <template
          #default="scope"
        >Lv.{{ scope.row.level+1 }} {{ scope.row.grade ? scope.row.grade.name : scope.row.level_text }}</template>
      </el-table-column>
      <el-table-column label="状态" width="100">
        <template #default="scope">
          <el-tag
            :type="scope.row.status != 0 ? 'danger' : 'success'"
            size="small"
          >{{ scope.row.is_stop ? scope.row.status_text : "正常" }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="分销" width="80">
        <template #default="scope">{{ scope.row.is_referrer ? "是" : "" }}</template>
      </el-table-column>
      <el-table-column label="身份证号" prop="idcard" width="180"></el-table-column>
      <el-table-column label="资金账号" width="200">
        <template #default="scope">
          <div v-for="x in scope.row.accounts" :key="x.id">
            {{ x.type_text }}：{{ x.decimal_balance }}
            <el-tag v-if="x.is_wrong" type="danger" size="small">异常</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="活跃时间" prop="updated_at" width="180"></el-table-column>
      <el-table-column label="备注" prop="remark" min-width="180"></el-table-column>
      <el-table-column label="创建时间" prop="create_at" width="180"></el-table-column>
      <el-table-column label="操作" width="140" fixed="right">
        <template #default="scope">
          <el-button type="primary" link size="small" icon="edit" @click="onEdit(scope.row)">修改</el-button>
          <el-button type="default" link size="small" @click="onSetGrade(scope.row)">设置等级</el-button>
          <!-- <el-popconfirm
            v-if="!scope.row.is_super"
            title="您确定要删除？"
            @confirm="onDelete(scope.row)"
          >
            <template #reference>
              <el-button size="small" type="text" class="btn-del"
                >删除</el-button
              >
            </template>
          </el-popconfirm>-->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="tableData.counts > 0"
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :current-page="query.pageIndex"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="tableData.counts"
    ></el-pagination>
  </el-card>

  <el-dialog title="设置用户等级" v-model="dialogVisible" width="600px" @close="current_grade=null">
    <el-descriptions v-if="current_grade">
      <el-descriptions-item label>
        <el-avatar :size="50" :src="current_grade.avatar_url" />
      </el-descriptions-item>
      <el-descriptions-item label="名称：">{{ current_grade.nick_name }}</el-descriptions-item>
      <el-descriptions-item label="手机：">{{ current_grade.mobile_phone }}</el-descriptions-item>
    </el-descriptions>
    <el-form
      :model="gradeForm"
      ref="grade_form"
      :rules="grade_rules"
      label-width="140px"
      :inline="false"
    >
      <el-form-item label="等级" prop="level">
        <el-select v-model="gradeForm.level" placeholder>
          <el-option
            v-for="item in mg_grades"
            :key="item.level"
            :label="item.name"
            :value="item.level"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button type="primary" @click="onGradeSubmit" :loading="grade_saving">确定</el-button>
    </template>
  </el-dialog>
  <edit :model="current" @success="loadData" @close="current=null;"></edit>
</template>

<script>
import Edit from "./edit.vue";
export default {
  components: { Edit },
  data() {
    return {
      loading: false,
      dialogVisible: false,
      grade_saving: false,
      query: {
        pageIndex: 1,
        pageSize: 20,
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
      },
      tableData: {
        counts: 0,
      },
      //修改
      current: null,
      //设置等级
      current_grade: null,
      gradeForm: {},
      grade_rules: {
        level: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      mg_grades: [],
    };
  },
  created() {
    this.$http.get("admin/v1/common/mb/grade").then((res) => {
      if (res.code == 0) {
        this.mg_grades = res.data;
      }
    });
    this.loadData();
  },
  methods: {
    /**
     * 查询搜索
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    /**
     * 加载数据
     */
    loadData() {
      this.loading = true;
      this.$http.post("admin/v1/member", this.query).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
        this.loading = false;
      });
    },

    /**
     * 排序
     */
    onSort(e) {
      if (e.prop) {
        this.query.order.sort = e.order;
        this.query.order.fieldName = e.prop;
        this.loadData();
      }
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },
    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },

    /**
     * 设置等级
     * @param {*} item
     */
    onSetGrade(item) {
      this.dialogVisible = true;
      this.current_grade = item;
      this.gradeForm = {
        id: item.id,
        level: item.level,
      };
    },

    /**
     * 提交等级更改
     */
    onGradeSubmit() {
      this.grade_saving = true;
      this.$http
        .post("admin/v1/member/update_grade", this.gradeForm)
        .then((res) => {
          if (res.code == 0) {
            this.$message.success(res.msg);
            this.loadData();
            this.dialogVisible = false;
          }
        })
        .finally(() => {
          this.grade_saving = false;
        });
    },

    /**
     * 修改
     * @param {*} item
     */
    onEdit(item) {
      this.current=item;
    },
  },
};
</script>

<style scoped>
.query .el-form-item {
  margin-bottom: 0;
}
</style>