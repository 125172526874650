<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2022-05-07 14:57:21
 * @LastEditors: cts 308360781@qq.com
 * @LastEditTime: 2025-01-15 10:44:59
-->

<template>
  <el-card class="box">
    <div class="avatar">
      <el-avatar
        :src="model.avatar_pic ? model.avatar_pic : avatar"
      ></el-avatar>
    </div>
    <el-descriptions>
      <el-descriptions-item label="姓名">{{ model.name }}</el-descriptions-item>
      <el-descriptions-item label="账号">{{
        model.account
      }}</el-descriptions-item>
      <el-descriptions-item label="手机">{{
        model.mobile_phone
      }}</el-descriptions-item>

      <el-descriptions-item label="角色">{{
        model.is_super ? "超级管理员" : model.roles.map((x) => x.name).join("")
      }}</el-descriptions-item>
      <el-descriptions-item label="创建时间">{{
        model.create_at
      }}</el-descriptions-item>
    </el-descriptions>
  </el-card>
  <el-card class="box">
    <el-descriptions title="安全设置" :column="2">
      <el-descriptions-item label="密码">
        <el-button type="primary" link @click="visiblePasswordDialog = true"
          >修改</el-button
        >
      </el-descriptions-item>
    </el-descriptions>
  </el-card>

  <el-dialog
    title="修改密码"
    v-model="visiblePasswordDialog"
    width="600px"
    draggable
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="原密码" prop="OldPwd">
        <el-col :span="16" :offset="0">
          <el-input
            v-model="form.OldPwd"
            type="password"
            clearable
            placeholder="请输入原密码"
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="密码" prop="Password">
        <el-col :span="16" :offset="0">
          <el-input
            v-model="form.Password"
            clearable
            placeholder="请输入新密码"
          ></el-input>
        </el-col>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button type="primary" :loading="saving" @click="onSubmitPwd"
        >确定</el-button
      >
    </template>
  </el-dialog>
</template>

<script>
import avatar from "@/assets/avatar.png";
import center_api from "@/http/center_api";
import md5 from "js-md5";

export default {
  data() {
    return {
      //本地默认头像
      avatar: avatar,
      visiblePasswordDialog: false,
      //个人资料
      model: {
        roles: [],
      },
      form: {},
      rules: {
        OldPwd: [{ required: true, message: "请输入原密码", trigger: "blur" }],
        Password: [
          { required: true, message: "请输入新密码", trigger: "blur" },
        ],
      },
      saving: false,
    };
  },
  created() {
    /**
     * 获取个人资料
     */
    center_api.get_my().then((res) => {
      if (res.code == 0) {
        this.model = res.data;
      }
    });
  },
  methods: {
    /**
     *
     */
    onSubmitPwd() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          var oldPwd = md5(this.form.OldPwd + this.model.salt);
          var newPwd = md5(this.form.Password + this.model.salt);
          var data = {
            oldPwd: oldPwd,
            newPwd: newPwd,
          };
          center_api
            .updatePwd(data)
            .then((res) => {
              if (res.code == 0) {
                this.$message.success("修改成功");
                this.visiblePasswordDialog = false;
              } 
            })
            .finally(() => {
              this.saving = false;
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.avatar {
  margin-bottom: 30px;
}
</style>
