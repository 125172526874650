/*
 * @Description: 获取个人资料
 * @Author: 琢磨先生
 * @Date: 2022-11-25 10:26:43
 * @LastEditors: cts 308360781@qq.com
 * @LastEditTime: 2025-01-15 10:47:29
 */

import http from "./axios_init";
export default {
    /**
     * 获取个人资料
     * @returns
     */
     get_my() {
      return http.get("admin/v1/center");
    },

    /**
     * 修改密码
     * @param {*} data 
     * @returns 
     */
    updatePwd(data){
      return http.post("admin/v1/center/changepwd",data);
    }
    
  };
  