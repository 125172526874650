<!--
 * @Description: 会员等级
 * @Author: 琢磨先生
 * @Date: 2022-06-17 15:19:50
 * @LastEditors: cts 308360781@qq.com
 * @LastEditTime: 2025-01-07 17:03:16
-->

<template>
  <!-- <el-card class="box">
    <el-button type="primary" size="small" @click="onInit" round icon="plus"
      >初始化等级</el-button
    >
  </el-card>-->
  <el-card class="box data">
    <el-table :data="tableData" border>
      <el-table-column label="等级" prop="level" width="100">
        <template #default="scope">{{ scope.row.level + 1 }}</template>
      </el-table-column>
      <el-table-column label="图片" width="240">
        <template #default="scope">
          <el-image :src="scope.row.icon" fit="scale-down" style="width: 200px; height: 100px"></el-image>
        </template>
      </el-table-column>
      <el-table-column label="等级名称" prop="name" min-width="180"></el-table-column>
      <el-table-column label="停用" width="100">
        <template #default="scope">
          <el-link
            :underline="false"
            :type="scope.row.is_stop?'danger':'success'"
          >{{ scope.row.is_stop? '停用':'正常' }}</el-link>
        </template>
      </el-table-column>
      <el-table-column label="升级方式" prop="upgrade_way_text" width="140"></el-table-column>
      <el-table-column label="升级积分" prop="total_score" width="140"></el-table-column>
      <el-table-column label="升级金额(元)" width="140">
        <template #default="scope">￥{{ scope.row.decimal_amount }}</template>
      </el-table-column>
      <el-table-column label="创建时间" prop="create_at" width="180"></el-table-column>
      <el-table-column label="操作" fixed="right" width="140">
        <template #default="scope">
          <el-button type="primary" link size="small" @click="onEdit(scope.row)">修改</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
  <el-dialog :title="`修改等级Lv${form.level + 1}`" v-model="visibleDialog" width="600px">
    <el-alert
      v-if="form.level == 0"
      type="info"
      title="一级为默认等级，无发设置升级方式"
      :closable="false"
      style="margin-bottom: 20px"
    ></el-alert>
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="展示图片">
        <upload-avatar @change="uploadChange" :src="form.icon" :aspectRatio="2"></upload-avatar>
      </el-form-item>
      <el-form-item label="名称" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="升级方式" v-if="form.level != 0">
        <el-radio-group v-model="form.upgrade_way">
          <el-radio
            :label="x.value"
            v-for="x in menus.grade_upgrade_way"
            :key="x.value"
          >{{ x.text }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="升级积分" v-if="form.upgrade_way != 1 && form.level != 0">
        <el-col :span="10">
          <el-input v-model="form.total_score" placeholder="升级所需要的积分"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="升级金额" v-if="form.upgrade_way != 0">
        <el-col :span="10">
          <el-input v-model="form.decimal_amount" placeholder="升级所需要的金额"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="停用" v-if="form.level != 0">
        <el-switch v-model="form.is_stop"></el-switch>
      </el-form-item>
      
    </el-form>

    <template #footer>
      <el-button type="primary" @click="onSubmit" :loading="saving">确定</el-button>
    </template>
  </el-dialog>
</template>
<script>
import common_api from "@/http/common_api";
import UploadAvatar from "../../upload/upload_avatar.vue";
export default {
  components: {
    UploadAvatar,
  },
  data() {
    return {
      visibleDialog: false,
      saving: false,
      tableData: [],
      form: {},
      rules: {
        name: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      menus: {},
    };
  },
  created() {
    this.loadData();
    common_api.get_enums().then((res) => {
      if (res.code == 0) {
        this.menus = res.data;
      }
    });
  },
  methods: {
    /**
     * 加载数据
     */
    loadData() {
      this.$http.get("admin/v1/grade").then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
      });
    },

    /**
     * 新增、修改
     */
    onEdit(item) {
      this.visibleDialog = true;
      this.form = Object.assign({}, item);
    },
    /**
     * icon图片上传
     */
    uploadChange(url) {
      this.form.icon = url;
    },
    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("/admin/v1/grade/edit", this.form)
            .then((res) => {
              this.saving = false;
              if (res.code == 0) {
                this.visibleDialog = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.form = {};
                this.loadData();
              }
            })
            .cath(() => {
              this.saving = false;
            });
        }
      });
    },
  },
};
</script>